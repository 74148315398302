import * as React from 'react';
import MapComponent from '../components/mapComponent';
import Shell from '../layout/shell';
import { ClassNames, SwitchInput } from '@rocketmakers/armstrong-edge';
import { AppContext } from '../context/appProvider';
import './index.scss';
import FilterList from '../components/filterList';
import CompanyPanel from '../components/companyPanel';
import Footer from '../components/footer';
import { ILocation } from '../typings/data';

import cross from '../assets/icons/cross.svg';

const Index: React.FunctionComponent<{}> = () => {
  const { filteredLocations, filterMode, setFilterMode } = React.useContext(AppContext);

  const [selectedLocation, setSelectedLocation] = React.useState<ILocation>();
  const [filtersPanelOpen, setFiltersPanelOpen] = React.useState(true);

  return (
    <Shell>
      <MapComponent
        mode={filterMode === 'tech-for-good' ? 'light' : 'dark'}
        locations={filteredLocations}
        onMarkerClick={setSelectedLocation}
      />

      <div className={!filtersPanelOpen ? 'filters closed' : 'filters open'}>
        <div className="panel-tab-container" onClick={() => setFiltersPanelOpen(!filtersPanelOpen)}>
          <div className="panel-tab">
            {filtersPanelOpen ? <img src={`/icons/left.svg`} /> : <img src={`/icons/right.svg`} />}
            <p>Filter by Category</p>
          </div>
        </div>
        {filtersPanelOpen && (
          <>
            <div className="filters-intro">
              <div className="cross-mark" onClick={() => setFiltersPanelOpen(false)}>
                <img src={cross} />
              </div>
              <h2>{filterMode === 'tech-for-good' ? 'Tech for Good Companies' : 'The Support Community'}</h2>
              {filterMode === 'tech-for-good' && (
                <p>
                  Tech for Good companies are product and services based businesses specifically using technology to
                  deliver positive impact.
                </p>
              )}
              {filterMode === 'support' && (
                <p>The support community exists to enable those working towards a positive impact.</p>
              )}
            </div>
            <div className={ClassNames.concat('filters-toggle', filterMode)}>
              <SwitchInput
                checked={filterMode === 'support'}
                onChange={(e) => setFilterMode(filterMode === 'tech-for-good' ? 'support' : 'tech-for-good')}
              />
              {filterMode === 'tech-for-good' ? 'The Support Community' : 'Tech for Good Companies'}
            </div>
            <FilterList />
          </>
        )}
      </div>
      {selectedLocation && <CompanyPanel location={selectedLocation} />}
      <Footer />
    </Shell>
  );
};

export default Index;
