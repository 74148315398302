import * as React from 'react';

import ReactMapboxGl, { Marker, Cluster, ZoomControl } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// import mapboxgl from "mapbox-gl";
// import "mapbox-gl/dist/mapbox-gl.css";

import './mapComponent.scss';
import { ILocation } from '../typings/data';
import { Props } from 'react-mapbox-gl/lib/map';

interface IProps {
  locations: ILocation[];
  onMarkerClick: (location: ILocation) => void;
  mode: 'light' | 'dark';
}

const lightTheme = 'mapbox://styles/adamrocketmakers/cktu6eax7108g18o4suk1tirb';
const darkTheme = 'mapbox://styles/adamrocketmakers/cko31gu030ztg17mmr02cwqe7';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const bristol = [-2.58791, 51.454514];

const MapComponent: React.FunctionComponent<IProps> = ({ locations, onMarkerClick, mode }) => {
  const MapBox = React.useMemo<any>(
    () =>
      ReactMapboxGl({
        accessToken:
          'pk.eyJ1IjoiYWRhbXJvY2tldG1ha2VycyIsImEiOiJja3RzajcwN3EwY3R3Mm9vM2xibm03aXg5In0.EHnwwvkHHaIihlCD5wtf2A',
        maxZoom: 15,
      }),
    []
  );

  const [zoom, setZoom] = React.useState(12);

  const mapRef = React.useRef<mapboxgl.Map>();
  const mapLoaded = React.useCallback((map: mapboxgl.Map) => {
    mapRef.current = map;
  }, []);

  const clusterMarker = (
    coordinates: [lat: number, lng: number],
    pointCount: number,
    getLeaves: (limit?: number, offset?: number) => Array<React.ReactElement<Props> | undefined>
  ) => {
    if (zoom < 14) {
      return (
        <Marker coordinates={coordinates}>
          <div className="cluster-marker cluster-marker-counter">
            <div>{pointCount}</div>
          </div>
        </Marker>
      );
    } else {
      return (
        <Marker coordinates={coordinates}>
          <div className="cluster-marker">
            <div>{renderCollection(getLeaves)}</div>
          </div>
        </Marker>
      );
    }
  };

  const renderCollection = (
    getLeaves: (limit?: number, offset?: number) => Array<React.ReactElement<Props> | undefined>
  ) => {
    const leaves = getLeaves();
    const locs = leaves.map((l, i) => (
      <div
        className="map-pin-wrapper"
        {...(l?.props as any)}
        style={{
          transform: `rotateZ(${(360 / leaves.length) * i}deg) translateY(5px)`,
          zIndex: i,
        }}
      >
        <img
          alt={l?.props['data-category']}
          className="map-pin"
          src={`/pins/${l?.props['data-category']}.svg`}
          style={{
            transform: `rotateZ(-${(360 / leaves.length) * i}deg)`,
          }}
        />
      </div>
    ));
    return <div className="multi-wrapper">{locs}</div>;
  };
  const handleMarkerClick = (loc: ILocation) => {
    mapRef.current?.flyTo({ center: [loc.location[1], loc.location[0]] });
    onMarkerClick(loc);
  };
  return (
    <>
      {MapBox && (
        <MapBox
          onStyleLoad={mapLoaded}
          style={mode === 'light' ? lightTheme : darkTheme}
          animationOptions={{ animate: true }}
          containerStyle={{
            height: '100%',
            width: '100%',
          }}
          center={bristol}
          zoom={[zoom]}
          onZoom={(e) => setZoom(mapRef.current?.getZoom()!)}
        >
          <>
            <ZoomControl />
            <Cluster ClusterMarkerFactory={clusterMarker} zoomOnClick={true}>
              {locations.map((location) => (
                <Marker
                  onClick={() => handleMarkerClick(location)}
                  offset={[-10, 0]}
                  key={location.name}
                  data-category={location.category}
                  coordinates={[location.location[1], location.location[0]]}
                  anchor="bottom"
                >
                  <img alt={location.category} className="map-pin" src={`/pins/${location.category}.svg`} />
                </Marker>
              ))}
            </Cluster>
          </>
        </MapBox>
      )}
    </>
  );
};

export default MapComponent;
